import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SkillsExperience from "../components/skills"


const Skills = (props) => (
  <Layout>
    <SEO title="Skills & Experience"
         description="List of all my skills and expertise"/>
    <SkillsExperience/>
  </Layout>
)

export default Skills