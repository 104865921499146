import React from "react"
import { Container, Row, Col, Spinner } from "react-bootstrap"
import { motion } from "framer-motion"
import "./skills.scss"
import { Link } from "gatsby"

let skills = [
  { name: "HTML", level: 20, url: "https://en.wikipedia.org/wiki/HTML" },
  { name: "CSS3", level: 20, url: "https://en.wikipedia.org/wiki/Cascading_Style_Sheets" },
  { name: "MySQL", level: 10, url: "https://en.wikipedia.org/wiki/MySQL" },
  { name: "Javascript", level: 30, url: "https://en.wikipedia.org/wiki/JavaScript" },
  { name: "React JS", level: 30, url: "https://en.wikipedia.org/wiki/React_(JavaScript_library)" },
  { name: "Redux", level: 15, url: "http://redux.js.org/" },
  { name: "MongoDB", level: 30, url: "https://www.mongodb.com/" },
  { name: "GatsbyJS", level: 30, url: "https://www.gatsbyjs.org/" },
  { name: "NodeJS", level: 30, url: "https://en.wikipedia.org/wiki/Node.js" },
  { name: "Git", level: 30, url: "https://en.wikipedia.org/wiki/Git" },
  { name: "GraphQL", level: 10, url: "https://graphql.org/" },
  { name: "Solidity", level: 10, url: "https://solidity.readthedocs.io/en/v0.6.1/" },
  { name: "Adobe Fireworks", level: 20, url: "https://en.wikipedia.org/wiki/Adobe_Fireworks" },
  { name: "Magento", level: 15, url: "https://magento.com/" },
  { name: "Express.js", level: 15, url: "http://expressjs.com/" },
  { name: "SCSS", level: 20, url: "https://en.wikipedia.org/wiki/Sass_(stylesheet_language)" },
  { name: "Web3", level: 15, url: "https://web3js.readthedocs.io/en/v1.2.4/" },
]
let settings = {
  textColour: "#08fdd8",
  textHeight: 20,
  depth: 0.7,
  weight: true,
  weightFrom: "data-weight",
  fadeIn: 2000,
  freezeDecel: true,
  freezeActive: true,
  animTiming: "Smooth",
  initial: [0.2, -0.1],
  zoom: 0.9,
}

const SkillsExperience = () => {
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    getTagCanvas()
  }, [])

  const getTagCanvas = () => {
    const script = document.createElement("script")
    script.src = "https://www.goat1000.com/tagcanvas.js"
    script.type = "text/javascript"
    script.async = true
    document.body.appendChild(script)
    script.addEventListener("load", loadSkillSphere)
  }

  const loadSkillSphere = () => {
    setTimeout(function() {
      setLoading(false)
      window.TagCanvas.Start("myCanvas", "", settings)
    }, 500)
  }

  let createSphere = () => {
    let r = []
    for (let i = 0; i < skills.length; i++) {
      r.push(<li key={i}><a data-weight={skills[i].level} href={skills[i].url}
                            target="_blank" rel="noopener noreferrer">{skills[i].name}</a></li>)
    }
    return <ul>{r}</ul>
  }

  return (
    <motion.section id="skills" initial={{ scale: 0.5 }} animate={{ scale: 0.96 }}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={6} className="text-container">
            <h1>Skills</h1>
            <p>I have been a successful freelance software developer since 2015 having built projects like weather
              apps, online javascript video games, Blockchain transcript verification for universities etc.
            </p>
            <p>Please use my contact form on this site to get in touch.</p>
            <div className="buttons-container">
              <motion.div whileTap={{ scale: 0.6 }}>
                <Link to="/contact" activeClassName="active" className="premium-button">Contact Me</Link>
              </motion.div>
            </div>
          </Col>
          <Col sm={12} lg={6} className="skills-sphere">
            {loading ?
              <Spinner animation="border"/> :
              <div id="myCanvasContainer">
                <canvas width="600" height="600" id="myCanvas">
                  {createSphere()}
                </canvas>
              </div>
            }

          </Col>
        </Row>
      </Container>
    </motion.section>
  )
}

export default SkillsExperience
